import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";

import SearchBlock from "components/search/SearchBlock";
import { page } from "components/page";

let ispjournals = "";

@withI18next(["common"])
@page
class Env extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      resourceType: "databases",
      pageContent: "",
    };

    ispjournals = props.appStore.globalConfig["jumper.common.ispjournals"];
  }

  componentDidMount() {
    //console.log(this.props);
    //this.getDetail(this.props)
  }

  render() {
    console.log("1111");
    console.log(this.props);
    console.log("2222");
    let { readerStore } = this.props;
    let { session } = readerStore;

    console.log(session);
    console.error(session);
    console.log("3333");

    console.log(Object.keys(session));

    return (
      <Layout {...this.props}>
        <div className="main sp" id="center">
          <SearchBlock />
          <section className="main_content">
            <div className="container">
              <a
                className="accesskey"
                href="javascript:;"
                id="aC"
                accessKey="C"
                title={this.props.t("jumperrwd.common.mainContenctArea")}>
                :::
              </a>
              <ul>
                {Object.keys(session).map((key, index) => (
                  <li>
                    {index}. {key} = {session[key]}
                  </li>
                ))}
              </ul>
              <hr />

              <p>ispjournals={ispjournals}</p>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default Env;
